<script lang="ts">
  import SvelteMarkdown from 'svelte-markdown'
  import "./i18n"
  import { _, locale} from 'svelte-i18n'
  import { marked } from 'marked'
  import WordLink from './WordLink.svelte';


  let player: HTMLAudioElement;

  let audioFile: string;
  let explanation: unknown;
  let langs = ['en', 'pt'];
  let lang = 'en';

  let consonants = [
    '01-p',
    '02-b',
    '03-t',
    '04-d',
    '05-k',
    '06-g',
    '07-f',
    '08-v',
    '09-s',
    '10-z',
    '11-ʃ',
    '12-ʒ',
    '13-l',
    '14-ʎ',
    '15-ɫ',
    '16-ɾ',
    '17-R',
    '18-m',
    '19-n',
    '20-ɲ',
  ];

  let vowels = [
    '01-a',
    '02-ɐ',
    '03-ɐ̃',
    '04-ɛ',
    '05-e',
    '06-ẽ',
    '07-ɨ',
    '08-i',
    '09-ĩ',
    '10-ɔ',
    '11-o',
    '12-õ',
    '13-u',
    '14-ũ',
  ];

  let semivowels = ['01-w', '02-w̃', '03-j', '04-j̃'];

  let explanations = {
    vowels: {
      '01-a': {
        en: `\\<a> open

This sound can appear written as:

- a - [comprar](), [casal](), [rapaz](), [casa](), [Marta]()
- á - [está]()*
- à - [à]()

*Not to be confused with "[esta]()"

The sound is open when the vowel belongs to the strong syllable (also called tonic). A syllable is strong when:
1. There is an accent: [está]() -> es-tá and à
2. It belongs to the last syllable of the word and the syllable ends in:
  - r: [comprar]() -> com-prar
  - z: [rapaz]() -> ra-paz
  - l: [casal]() -> ca-sal
  - i: [sorri]() -> so-rri
3. It belongs to the second to last syllable and there is no accent nor the word ends in -i, -r, -z or -l: [casa](), [Marta]()`,
        pt: `\\< a> médio ou central, aberto, oral

Este som pode aparecer escrito como:
a - [comprar](), [casal](), [rapaz](), [casa](), [Marta]()
á - [está]()*
à - [à]()

*Não confundir com “[esta]()”

O som é aberto quando a vogal pertence à sílaba forte (também chamada tónica). Uma sílaba é forte quando:
1. Há um acento: [está]() -> es-tá e à
2. Pertence à última sílaba da palavra e a sílaba termina em:
  - r: [comprar]() -> com-prar
  - z:    [rapaz]() -> ra-paz
  - l:     [casal]() -> ca-sal
  - i:      [sorri]() -> so-rri
3. Pertence à penúltima sílaba (a segunda sílaba a contar do fim) e não há nenhum acento nem a palavra termina em -i, -r, -z ou -l: [casa](), [Marta]()`,
      },
    },
  };

  function play(audio: string) {
    audioFile = `/audio/${audio}.m4a`;
    player.pause();
    player.currentTime = 0;
    player.addEventListener(
      'loadedmetadata',
      () => {
        player.play();
      },
      { once: true }
    );
    player.load();
  }

  function stripPrefix(w) {
    return w.split('-')[1];
  }

  function getExplanation(phoneme, type) {
    let explanation = explanations[type]?.[phoneme];
    if (explanation) {
      return explanation[lang];
    } else {
      return { en: 'No explanation yet', pt: 'Nenhuma explicação ainda' }[lang];
    }
  }

  function showExplanation(phoneme, type) {
    let markdown = getExplanation(phoneme, type);


    const tokens = marked.lexer(markdown);

    marked.walkTokens(tokens, token => {
      if (token.type == 'link' && token.href == '') {
      	token.title = token.text;
      }
    })

    explanation = tokens

  }
</script>

<main>
  <p class="lang-select">
    {#each langs as l}
    <a on:click="{() => $locale = l}">{l}</a>
    &nbsp; {/each}
  </p>

  locale: {$locale}

  <h1 class="main-heading">
    <img src="/images/logo.png" alt="Logo" class="logo" />
    Fonética Portuguesa
  </h1>

  <audio bind:this="{player}" src="{audioFile}" style="display: none" />

  <h2>{$_('consonants')}</h2>
  {#each consonants as consonant}
  <button on:click="{() => play(`consonants/${consonant}`)}">
    {stripPrefix(consonant)}
  </button>

  &nbsp; {/each}
  <h2>{$_('vowels')}</h2>
  {#each vowels as vowel}
  <button on:click="{ () => play(`vowels/${vowel}`) }" on:mouseenter="{ () => showExplanation(vowel, "vowels") }" >
    {stripPrefix(vowel)}
  </button>

  &nbsp; {/each}
  <h2>{$_('semivowels')}</h2>
  {#each semivowels as semivowel}
  <button on:click="{() => play(`semivowels/${semivowel}`)}">
    {stripPrefix(semivowel)}
  </button>
  &nbsp; {/each} {#if explanation}
  <p class="explanation"><SvelteMarkdown source={explanation} renderers={{link: WordLink}}/></p>
  {/if}
</main>

<style>
  main {
    text-align: center;
    padding: 1em;
    max-width: 240px;
    margin: 0 auto;
  }

  h1 {
    color: #E50038;
    font-family: 'Portuguesa-Caps';
    font-size: 4em;
  }

  @media (min-width: 640px) {
    main {
      max-width: none;
    }
  }

  p.explanation {
    white-space: pre-wrap;
    padding: 1em;
    background: #66ccff;
    border: 1px solid #4b97bd;
    margin: 1em auto;
    max-width: 800px;
    text-align: left;
  }

  p.lang-select {
    float: right;
  }

  .main-heading {
  }
  img.logo {
    width: 100px;
    height: 100px;
    top: 0px;
    right: 0px;
    vertical-align: middle;
    margin-right: 10px;
  }
</style>
