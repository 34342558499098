<script lang="ts">
  export let href = '';
  export let title = undefined;

  let player: HTMLAudioElement;
  let audioFile: string;

  function play() {
    audioFile = `/audio/words/${title}.m4a`;
    player.pause();
    player.currentTime = 0;
    player.addEventListener(
      'loadedmetadata',
      () => {
        player.play();
      },
      { once: true }
    );
    player.load();
  }
  function playSlow() {
    audioFile = `/audio/words/${title}.slow.m4a`;
    player.pause();
    player.currentTime = 0;
    player.addEventListener(
      'loadedmetadata',
      () => {
        player.play();
      },
      { once: true }
    );
    player.load();
  }
</script>

<a {title} on:click="{play}">
  <slot></slot>
</a>
<sup><a on:click="{playSlow}">slow</a></sup>

<audio bind:this="{player}" src="{audioFile}" style="display: none" />
